@font-face {
  font-family: starwars;
  src: url(starwars.ttf);
}

.Nav{
  display: flex;
  flex-direction: row;
  justify-content:center;
  background: rgba(0, 0, 0, 0.9);
}

.Button{
  background: none;
  border: 2px solid yellow;
  color: yellow;
}

body{
  width: 100vw;
  height: 100vh;
  overflow: none;
  background: black;
}

.StarWarsFont{
  font-family: starwars;
  font-size: 50px;
}

.StarWarsSecond{
  font-family: starwars;
  font-size: 30px;
}

.App {
  width: 100vw;
  height: 100vh;
  overflow: none;
  background-image: url("background.png");
  background-repeat: no-repeat;
  background-position: center; /* Center the image */
  background-size: cover;
  color: yellow;
}

.Form {
  margin: 40px;
  border-radius: 15;
  background: rgba(0, 0, 0, 0.6);
  padding: 20px;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
